import React from "react"
import { Wrapper, Container } from "../styles"
import SEO from "../components/seo"
import loadable from '@loadable/component'
import { LoadingSpinner } from "@rachio-npm/rachio-retail-common";
const DistributorMap = loadable(() => (
  /* webpackChunkName: "[request]" */ import("../components/DistributorMap")
), {
  fallback: <LoadingSpinner />,
});

const WhereToBuyPage = () => {
  return (
    <>
      <SEO title="Where to Buy" pathname="/where-to-buy/" />
      <Wrapper>
        <div className="center-text">
          <h1 className="blue-text">
            Where To Buy
          </h1>
          <p>
            Rachio partners with irrigation distributors to provide in-store same-day pickup and education.
            We encourage all contractors to establish an account and relationship with their local irrigation
            supplier which provides the best pricing, availability, purchase terms, and rewards.
          </p>
          <p>
            Can’t find Rachio near you? <a href="mailto:prosupport@rachio.com">Tell us where you want to buy</a>.
          </p>
        </div>

        <hr css="padding-top: var(--basePadding)" />
        <Container>
          <DistributorMap />
        </Container>
      </Wrapper>
    </>
  )
}

export default WhereToBuyPage
